import React, { Component } from 'react'
import { Layout, Form, Input, Button, message, Select, DatePicker, InputNumber } from 'antd'
import { graphql } from 'react-apollo'
import { inject, observer } from 'mobx-react'
import Loading from '~/components/Loading/index'
import couponCodeTypeQuery from '~/graphql/queries/couponCodeType.gql'
import updateCouponCodeTypeMutation from '~/graphql/mutations/updateCouponCodeType.gql'
import createCouponCodeTypeMutation from '~/graphql/mutations/createCouponCodeType.gql'
import eventQuery from '~/graphql/queries/event.gql'
import { Link } from 'react-router-dom'
import EventSider from '~/components/Events/Event/Sider'
import _get from 'lodash/get'
import moment from 'moment-timezone'
import DeleteConfirmModal from './DeleteConfirmModal'
import { withTranslation } from 'react-i18next'
import config from '~/config'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  }
}
const toDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
const { RangePicker } = DatePicker
const toPercentage = (value) => {
  return value ? (value * 100).toFixed(2) : 0
}
const dateFormat = 'DD/MM/YYYY HH:mm:ss'

@inject('router')
@observer
@Form.create()
@graphql(couponCodeTypeQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.couponCodeTypeId,
      eventId: props.match.params.eventId
    }
  }),
  props: ({ data: { loading, couponCodeType } }) => ({
    couponCodeTypeLoading: loading,
    couponCodeType: couponCodeType
  }),
  fetchPolicy: 'network-only'
})
@graphql(eventQuery, {
  options: (props) => ({
    variables: {
      _id: props.match.params.eventId
    }
  }),
  props: (ep) => ({
    eventLoading: ep.data.loading,
    event: ep.data.event,
    ep
  }),
  fetchPolicy: 'network-only'
})
@graphql(createCouponCodeTypeMutation, { name: 'createCouponCodeTypeMutation' })
@graphql(updateCouponCodeTypeMutation, { name: 'updateCouponCodeTypeMutation' })
class Edit extends Component {
  state = {
    loading: false
  }

  handleSubmit = (e) => {
    const { couponCodeType, event } = this.props
    e.preventDefault()
    this.setState({ loading: true })
    this.props.form.validateFields((err, value) => {
      const validityPeriod = _get(value, 'validityPeriod')
      if (err) {
        this.setState({ loading: false })
        return
      }
      if (_get(value, 'discountType') === 'percentage') {
        value.discount = value.discount / 100
      }
      const record = {
        // _id: this.props.couponCodeType._id,
        ...value,
        masterType: 'RegistrationType',
        accessOverride: true,
        ...(validityPeriod && {
          validityPeriod: {
            start: moment(validityPeriod[0]._d).format(toDateTimeFormat),
            end: moment(validityPeriod[1]._d).format(toDateTimeFormat)
          }
        })
      }
      if (couponCodeType) {
        this.props.updateCouponCodeTypeMutation({
          variables: { record: {
            ...record,
            _id: this.props.couponCodeType._id
          } }
        }).then((response) => {
          message.success(`save successfully`)
        }).catch((e) => {
          message.error(`${e}`)
        }).finally(() => {
          this.setState({ loading: false })
        })
      } else {
        this.props.createCouponCodeTypeMutation({
          variables: { record: {
            ...record,
            eventId: event._id
          } }
        }).then((response) => {
          const couponCodeTypeId = _get(response, 'data.createCouponCodeType.record._id')
          message.success(`create successfully`)
          if (couponCodeTypeId) {
            this.props.history.push(`/fetchPage?link=/events/${event._id}/coupon-code-types/${couponCodeTypeId}/edit`)
          }
        }).catch((e) => {
          message.error(`${e}`)
        })
      }
    })
  }

  render () {
    const { couponCodeType, event, couponCodeTypeLoading, eventLoading, ep, history, t } = this.props
    if (couponCodeTypeLoading) return <Loading />
    if (eventLoading) return <Loading />
    const { getFieldDecorator, getFieldValue } = this.props.form
    const couponCodeTypeId = _get(this.props, 'match.params.couponCodeTypeId')
    console.log('event', event, ep.data, _get(ep, 'data.error.message'), this.props)
    if (_get(ep, 'data.error.networkError.type') === 'WriteError') {
      window.location.reload()
      return <Loading />
    }
    const eventId = _get(event, '_id')
    const eventName = _get(event, 'name.th', '') || _get(event, 'name.th', '')
    const registrationTypes = _get(event, 'registrationTypes')
    const ticketTypesTypes = _get(event, 'ticketTypes')
    const masterIds = _get(couponCodeType, 'masterIds')
    const ticketTypeIds = _get(couponCodeType, 'ticketTypeIds')
    const discountTypes = [{name: `${t('coupon.fixed')}`, value: 'flat'}, {name: '%', value: 'percentage'}]
    const validityPeriod = [
      moment.utc(_get(couponCodeType, 'validityPeriod.start')),
      moment.utc(_get(couponCodeType, 'validityPeriod.end'))
    ]
    const name = {
      en: _get(couponCodeType, 'name.en', ''),
      th: _get(couponCodeType, 'name.th', ''),
      ms: _get(couponCodeType, 'name.ms', ''),
    }
    const nameEnDecorator = getFieldDecorator('name.en', {
      rules: [{ required: true, message: `${t('coupon.messageEN')}` }],
      initialValue: name.en
    })
    const nameThDecorator = getFieldDecorator(`name.${config.default_locale}`, {
      rules: [{ required: true, message: `${t('coupon.message')} ${config.default_locale} name` }],
      initialValue: _get(couponCodeType, `name.${config.default_locale}`)
    })
    const masterIdsDecorator = getFieldDecorator('masterIds', {
      initialValue: masterIds,
      rules: [{ required: true, message: 'Please input master' }]
    })
    const ticketTypeIdsDecorator = getFieldDecorator('ticketTypeIds', {
      initialValue: ticketTypeIds
      // rules: [{ required: true, message: 'Please input ticketType' }]
    })
    const validityPeriodDecorator = getFieldDecorator('validityPeriod', {
      initialValue: validityPeriod,
      rules: [{ required: true, message: 'Please input Validity Period' }]
    })

    let discount = _get(couponCodeType, 'discount')
    let discountType = getFieldValue('discountType') || _get(couponCodeType, 'discountType')
    if (!discountType) discountType = discountTypes[0].value
    if (discountType === 'percentage') {
      discount = toPercentage(discount)
    }
    return <Layout>
      <EventSider />
      <div className="editContent code-box">
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            wrapperCol={ { xs: { span: 24 }, sm: { span: 18, offset: 6 } } }
          >
            <div className="modDeleteButton">
              <DeleteConfirmModal loading={this.props.loading} t={this.props.t}
                couponCodeTypeId={couponCodeTypeId} history={this.props.history}
                name={name.th || name.en} />
            </div>
            <h2 className="title">
              {couponCodeType ? t('coupon.editCoupon') : t('coupon.edit.newCoupon')}
            </h2>
          </FormItem>
          {couponCodeType && <FormItem
            label="ID"
            {...formItemLayout}
          >
            {couponCodeTypeId}
          </FormItem>}
          <FormItem
            label={`${t('coupon.edit.eventId')}`}
            {...formItemLayout}
          >
            {eventId}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.eventName')}`}
            {...formItemLayout}
          >
            {eventName}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.name')} [EN]`}
            {...formItemLayout}
          >
            {nameEnDecorator(
              <Input />
            )}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.name')} [${config.default_locale.toUpperCase()}]`}
            {...formItemLayout}
          >
            {nameThDecorator(
              <Input />
            )}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.registrationType')}`}
            {...formItemLayout}
          >
            { registrationTypes && masterIdsDecorator(
              <Select mode="multiple" showSearch>
                { registrationTypes.map(registrationType =>
                  <Select.Option key={registrationType._id} value={registrationType._id}>
                    {_get(registrationType, 'name.th') || _get(registrationType, 'name.en')}
                  </Select.Option>
                )}
              </Select>
            )}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.ticketType')}`}
            {...formItemLayout}
          >
            { ticketTypesTypes && ticketTypeIdsDecorator(
              <Select mode="multiple" showSearch>
                { ticketTypesTypes.map(ticketTypesType =>
                  <Select.Option key={ticketTypesType._id} value={ticketTypesType._id}>
                    {_get(ticketTypesType, 'name.th') || _get(ticketTypesType, 'name.en')}
                  </Select.Option>
                )}
              </Select>
            )}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.date')}`}
            {...formItemLayout}
          >
            { validityPeriodDecorator(
              <RangePicker showTime format={dateFormat} />
            )}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.discountType')}`}
            {...formItemLayout}
          >
            {getFieldDecorator(`discountType`, {
              initialValue: discountType
            })(<Select
              // mode="multiple"
              size='default'
              style={{ width: '100%' }}
            >
              {discountTypes.map(c =>
                <Select.Option key={c.value} value={c.value}>
                  {c.name}
                </Select.Option>
              )}
            </Select>)}
          </FormItem>
          <FormItem
            label={`${t('coupon.edit.discount')}`}
            {...formItemLayout}
          >
            { getFieldDecorator('discount', {
              initialValue: discount,
              rules: [{ required: true, message: `${t('coupon.edit.message')}` }]
            })(
              <InputNumber min={0} max={getFieldValue(`discountType`) === 'percentage' ? 100 : 1000000}/>
            ) }
          </FormItem>
          <FormItem
            wrapperCol={{xs: { span: 24 }, sm: { span: 16, offset: 8 }}}
          >
            <Button type="primary" htmlType="submit" loading={this.state.loading}>{`${t('coupon.edit.save')}`}</Button>
            <Link to={`/events/${eventId}/coupon-code-types/list`} style={{marginLeft: '10px'}}>
              <Button loading={this.state.loading}>{`${t('coupon.edit.cancel')}`}</Button>
            </Link>
          </FormItem>
        </Form>
      </div>
    </Layout>
  }
}

export default withTranslation() (Edit)
